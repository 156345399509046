import { Suspense, useRef, useState } from 'react'

import { HeadHelmet } from './components/Helmet'
import { Filters } from './components/Filters'
import Footer from './components/Footer'
import Header from './components/Header'
import Intro from './components/Intro'
import { ProjectList } from './components/List'

import Lab from './assets/others/lab.png'

import './css/AI.scss'

function App() {
  const [appFocus, setAppFocus] = useState('Recent and Best') // or 'All Publications' or 'Talks'

  const [filters, setFilters] = useState([])
  const publicationRef = useRef(null)

  const toggleAppFocus = targetFocus => {
    document.querySelectorAll('.project-item').forEach(projectItemEl => {
      projectItemEl.style.height = `${projectItemEl.scrollHeight}px`
    })

    setTimeout(() => {
      document.querySelectorAll('.project-item').forEach(projectItemEl => {
        projectItemEl.style.height = `unset`
      })
    }, 301)

    scrollToPublication()

    setAppFocus(targetFocus)
  }

  const toggleFilter = e => {
    let ele = e.target
    while (!ele.dataset?.category) {
      ele = ele.parentNode
      if (!ele) return
    }

    const {
      dataset: { category },
    } = ele

    // const newFilters = [...filters]
    // if (newFilters.includes(category)) {
    //   return setFilters(newFilters.filter(f => f !== category))
    // } else {
    //   return setFilters([...newFilters, category])
    // }
    document.querySelectorAll('.project-item').forEach(projectItemEl => {
      projectItemEl.style.height = `${projectItemEl.scrollHeight}px`
    })

    setTimeout(() => {
      document.querySelectorAll('.project-item').forEach(projectItemEl => {
        projectItemEl.style.height = `unset`
      })
    }, 301)

    scrollToPublication()

    const newFilters = [...filters]
    if (newFilters.includes(category)) {
      return setFilters([])
    } else {
      return setFilters([category])
    }
  }

  // const clearFilter = e => {
  //   scrollToPublication()
  //   if (filters.length > 0)
  //     toggleFilter({ target: { dataset: { category: filters[0] } } })
  // }

  const scrollToPublication = e => {
    if (window.scrollY < publicationRef.current?.offsetTop) {
      window.scrollTo({
        top:
          publicationRef.current?.offsetTop -
          document.getElementsByTagName('header')[0]?.offsetHeight -
          16 * 2,
        behavior: 'smooth',
      })
    }
  }

  return (
    <>
      <HeadHelmet
        title={'Creativity Lab'}
        description={'Creativity Lab at UC San Diego'}
        url={''}
        image={Lab}
      />
      <Header highlighted="research" />
      <div className="list-container list-container-projects">
        <Intro
          introContent={
            <p>
              <b>The Creativity Lab</b> at University of California San Diego,
              directed by Prof. Haijun Xia, aims to augment human creativity and
              productivity by{' '}
              <b>bridging human cognition and digital computation</b>. We
              develop dynamic and flexible representations of user interfaces as
              well as fluid and expressive interaction techniques with various
              modalities, like natural language input, gestural interaction, and
              AR / VR. Our research covers a wide range of tasks in design,
              communication, computer-supported cooperative work, information
              visualization, and many other domains.
            </p>
          }
        />
        <Suspense>
          <h2
            ref={publicationRef}
            className="list-h2"
            // onClick={clearFilter}
            style={{
              cursor: 'pointer',
            }}
          >
            <span
              style={{
                fontWeight: appFocus === 'Recent and Best' ? 'bold' : undefined,
              }}
              onClick={() => toggleAppFocus('Recent and Best')}
            >
              Recent and Best
            </span>{' '}
            /{' '}
            <span
              style={{
                fontWeight:
                  appFocus === 'All Publications' ? 'bold' : undefined,
              }}
              onClick={() => toggleAppFocus('All Publications')}
            >
              All Publications
            </span>{' '}
            /{' '}
            <span
              style={{
                fontWeight: appFocus === 'Talks' ? 'bold' : undefined,
              }}
              onClick={() => toggleAppFocus('Talks')}
            >
              Talks
            </span>
          </h2>
          {/* -------------------------------------------------------------------------- */}
          {appFocus === 'All Publications' && (
            <Filters filters={filters} toggleFilter={toggleFilter} />
          )}
          <ProjectList
            filters={
              appFocus === 'Recent and Best'
                ? ['recent']
                : appFocus === 'Talks'
                  ? ['talk']
                  : filters
            }
            negativeFilters={[]}
          />
          {/* -------------------------------------------------------------------------- */}
        </Suspense>
      </div>

      <Footer />
    </>
  )
}

export default App
