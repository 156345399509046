import React, { Component } from 'react'

import ReactMarkdown from 'react-markdown'
import rehypeRaw from 'rehype-raw'

import Footer from '../components/Footer'
import Header from '../components/Header'
import { ListLinks } from '../components/ListLink'
import {
  findPersonBio,
  findProject,
  parseProjectPaper,
} from '../components/utils'
import { PageNotFound } from './404'
import { HeadHelmet } from '../components/Helmet'

import Lab from '../assets/others/lab.png'

export default class Project extends Component {
  constructor(props) {
    super(props)

    this.state = {
      html: '',
      notAProject: false,
    }

    this.projectOrBioShortcut = null
    this.project = null
    this.person = null
  }

  getThisProjectPage() {
    const path = window.location.pathname.split('/')
    return (this.path = path[path.length - 1])
  }

  getProjectOrPerson() {
    this.projectOrBioShortcut = this.getThisProjectPage()
    this.project = findProject(this.projectOrBioShortcut)
    this.person = findPersonBio(this.projectOrBioShortcut)
  }

  componentDidMount() {
    this.getProjectOrPerson()

    if (this.project || this.person) {
      if (this.project) {
        // document.title = `${this.project.name} | Creativity Lab`

        fetch(
          require(`../assets/markdownPages/${this.projectOrBioShortcut}.md`)
        )
          .then(response => {
            return response.text()
          })
          .then(text => {
            this.setState({
              html: text,
            })
          })
      } else if (this.person) {
        // document.title = `${this.person.name} | Creativity Lab`
        fetch(
          require(`../assets/markdownPages/${this.projectOrBioShortcut}.md`)
        )
          .then(response => {
            return response.text()
          })
          .then(text => {
            this.setState({
              html: text,
            })
          })
      }
    } else if (!this.state.notAProject) {
      // document.title = `404 | Creativity Lab`
      this.setState({
        notAProject: true,
      })
    }
  }

  // ? why do we need it here
  componentDidUpdate() {
    this.getProjectOrPerson()

    if (this.project || this.person) {
      // if (this.project) document.title = `${this.project.name} | Creativity Lab`
      // else if (this.person)
      //   document.title = `${this.person.name} | Creativity Lab`
    } else if (!this.state.notAProject) {
      // document.title = `404 | Creativity Lab`
      this.setState({
        notAProject: true,
      })
    }
  }

  render() {
    const title = this.person
      ? `${this.person.name} | Creativity Lab`
      : this.project
        ? `${this.project.name} | Creativity Lab`
        : '404 | Not in Creativity Lab'

    return this.state.notAProject ? (
      <>
        <HeadHelmet
          title={title}
          description={'Creativity Lab at UC San Diego'}
          url={'404'}
          image={Lab}
        />
        <Header highlighted="" />
        <div className="page-view project-page-view">
          <PageNotFound />
        </div>
      </>
    ) : (
      <>
        <HeadHelmet
          title={title}
          description={'Creativity Lab at UC San Diego'}
          url={
            this.person
              ? (this.person.bio ?? '')
              : this.project?.shortcut
                ? this.project.shortcut
                : '404'
          }
          image={Lab}
        />

        <Header highlighted="" />

        <div className="page-view project-page-view">
          {/* -------------------------------------------------------------------------- */}
          {this.project && (
            <div className="project-meta">
              <h1>
                <b>{this.project.name}</b> <span>{this.project.title}</span>
              </h1>
              <p className="project-authors">{this.project.authors}</p>

              <ListLinks
                name={this.project.name}
                links={[
                  {
                    name: 'doi',
                    value: this.project.doi,
                  },
                  {
                    name: 'paper',
                    value: parseProjectPaper(this.project.paper),
                  },
                  {
                    name: 'video',
                    value: this.project.video,
                  },
                  {
                    name: 'preview',
                    value: this.project.preview,
                  },
                  // {
                  //   name: 'website',
                  //   value: parseProjectWebsite(this.project.website),
                  // },
                  {
                    name: 'bibtex',
                    value: this.project.bibTex,
                  },
                  ...(this.project.extra ?? []),
                ]}
              />
            </div>
          )}
          {/* -------------------------------------------------------------------------- */}
          {this.person && (
            <div className="project-meta">
              <div className="business-card">
                <img
                  src={require(`../assets/people/${this.person.photo}`)}
                  alt={this.person.name}
                  className="business-card-image"
                />
                <div className="card-content">
                  <h1>
                    <b>{this.person.name}</b>
                  </h1>
                  <p className="project-authors">{this.person.category}</p>
                </div>
              </div>
            </div>
          )}
          {/* -------------------------------------------------------------------------- */}
          <ReactMarkdown rehypePlugins={[rehypeRaw]}>
            {this.state.html}
          </ReactMarkdown>
        </div>

        <Footer />
      </>
    )
  }
}
