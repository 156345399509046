import React, {
  Component,
  useRef,
  useEffect,
  // useCallback,
  useState,
  memo,
} from 'react'
import { motion, AnimatePresence } from 'framer-motion'

import Publications from '../assets/publications.json'
import People from '../assets/people.json'

import { ListLinks } from './ListLink'
import {
  getAlumniTime,
  parseProjectAward,
  parseProjectPaper,
  parseProjectWebsite,
  parseProjectYear,
  projectHasCategory,
  sortPeopleList,
  sortPeopleListsByYear,
} from './utils'

import '../css/List.scss'
import { Highlights } from './Highlights'

export class ProjectList extends Component {
  render() {
    const {
      target,
      filters,
      negativeFilters,
      byPapers,
      papers,
      extraLists,
      fullConferenceName = false,
    } = this.props

    const renderTarget = target || 'home'
    const extraPaperLists = extraLists || []

    const publicationPapers = Publications.papers
    if (extraPaperLists.length > 0) {
      extraPaperLists.forEach(listName => {
        publicationPapers.push(...Publications.extra[listName])
      })
    }

    const projects = []

    // byPapers
    if (byPapers) {
      for (const paperInd in papers) {
        const project = publicationPapers.find(
          paper => paper.shortcut === papers[paperInd]
        )

        projects.push(
          <Project
            key={project.shortcut}
            target={renderTarget}
            show={true}
            order={paperInd}
            year={project.year}
            name={project.name}
            title={project.title}
            thumbnail={project.thumbnail}
            thumbnailHovered={project.thumbnail_hovered}
            authors={project.authors.split(', ')}
            award={project.award}
            doi={project.doi}
            paper={project.paper}
            video={project.video}
            preview={project.preview}
            website={project.website}
            bibTex={project.bibTex}
            extra={project.extra}
            highlights={project.highlights}
            fullConferenceName={fullConferenceName}
          />
        )
      }
    } else {
      for (const projectInd in publicationPapers) {
        const project = Publications.papers[projectInd]

        projects.push(
          <Project
            key={project.shortcut}
            target={renderTarget}
            show={
              ((filters.length === 0 &&
                !projectHasCategory(project.categories, ['talk'])) ||
                projectHasCategory(project.categories, filters)) &&
              !projectHasCategory(project.categories, negativeFilters)
            }
            order={projectInd}
            year={project.year}
            name={project.name}
            title={project.title}
            thumbnail={project.thumbnail}
            thumbnailHovered={project.thumbnail_hovered}
            authors={project.authors.split(', ')}
            award={project.award}
            doi={project.doi}
            paper={project.paper}
            video={project.video}
            preview={project.preview}
            website={project.website}
            bibTex={project.bibTex}
            extra={project.extra}
            highlights={project.highlights}
            fullConferenceName={fullConferenceName}
          />
        )
      }
    }

    return <ul className={`list-view project-list`}>{projects}</ul>
  }
}

export class PeopleList extends Component {
  render() {
    const { status, categories, info, byPeople, peopleList, showBioInline } =
      this.props

    let people = []

    if (byPeople) {
      people = peopleList.map(personName => {
        const person = People.directory.find(
          person => person.name === personName
        )

        return (
          <Person
            key={person.name}
            image={person.photo}
            name={person.name}
            title={person.category}
            research={person.intro}
            status={person.status}
            email={person.email}
            website={person.website}
            twitter={person.twitter}
            time={person.time}
            now={person.now}
            info={info}
            bio={person.bio}
            bioInline={person.bio_inline}
            showBioInline={showBioInline}
            // publications={person.publications}
          />
        )
      })
    } else {
      people = sortPeopleList(
        People.directory.filter(
          person =>
            !person.external_collaborator &&
            person.status === status &&
            (status === 'current' || categories.includes(person.category))
        ),
        People.ordering,
        status
      ).map(person => (
        <Person
          key={person.name}
          image={person.photo}
          name={person.name}
          title={person.category}
          research={person.intro}
          status={person.status}
          email={person.email}
          website={person.website}
          twitter={person.twitter}
          time={person.time}
          now={person.now}
          info={info}
          bio={person.bio}
          bioInline={person.bio_inline}
          showBioInline={showBioInline}
          // publications={person.publications}
        />
      ))
    }

    return (
      <ul
        className={`list-view people-list${
          status === 'alumni' && !info ? ' people-list-alumni' : ''
        }${status === 'alumni' && info ? ' people-list-alumni-important' : ''}`}
      >
        {people}
      </ul>
    )
  }
}

export class PeopleAlumniList extends Component {
  render() {
    const { status, categories, info } = this.props

    const peopleLists = sortPeopleListsByYear(
      People.directory.filter(
        person =>
          person.status === status &&
          (status === 'current' || categories.includes(person.category))
      ),
      People.ordering,
      status
    )

    const lists = []

    for (const year of [...Object.keys(peopleLists)].sort((a, b) => b - a)) {
      lists.push(
        <div key={'alumni-' + year} className="alumni-list">
          <h4 className="list-h4">{year}</h4>
          <ul
            className={`list-view people-list${
              status === 'alumni' && !info ? ' people-list-alumni' : ''
            }${
              status === 'alumni' && info ? ' people-list-alumni-important' : ''
            }`}
          >
            {peopleLists[year].map(person => (
              <Person
                key={person.name}
                image={person.photo}
                name={person.name}
                title={person.category}
                research={person.intro}
                status={person.status}
                email={person.email}
                website={person.website}
                twitter={person.twitter}
                time={person.time}
                now={person.now}
                info={info}
                bio={person.bio}
                // publications={person.publications}
              />
            ))}
          </ul>
        </div>
      )
    }

    return <>{lists}</>
  }
}

const Project = ({
  target,
  show,
  order,
  year,
  name,
  title,
  thumbnail,
  thumbnailHovered,
  authors,
  award,
  doi,
  paper,
  video,
  preview,
  website,
  bibTex,
  extra,
  highlights,
  fullConferenceName,
}) => {
  const [showCover, setShowCover] = useState(true)
  const coverRef = useRef(null)
  const coverVideoRef = useRef(null)

  let imageSource = require(`../assets/thumbnails/${thumbnail}`)

  let animatedImageSource =
    thumbnailHovered?.type === 'gif'
      ? require(`../assets/thumbnails/${thumbnailHovered?.source}`)
      : thumbnailHovered?.type === 'video'
        ? require(`../assets/videos/${thumbnailHovered?.source}`)
        : null

  // authors
  // const authorList = authors.map(author => (
  //   <span key={author} className="project-author">
  //     {author}
  //   </span>
  // ))
  const authorList = (
    <span className="project-author">{authors.join(', ')}</span>
  )

  const listItemEl = useRef(null)
  // useEffect(() => {
  //   const listItem = listItemEl.current

  //   if (listItem.clientHeight > 0)
  //     listItem.style.height = `${listItem.scrollHeight}px`
  //   else listItem.style.height = 'unset'
  // })

  const newProject = <span className="new-project-label">coming soon</span>

  const handleClick = e => {
    if (animatedImageSource && thumbnailHovered?.type === 'video') {
      if (e.target === coverRef.current) {
        setShowCover(false)
        setTimeout(() => {
          // coverVideoRef.current?.requestFullscreen()
          coverVideoRef.current?.classList.add('pop-up-video')
          coverVideoRef.current?.classList.add('pop-up-video-z-up')

          setTimeout(() => {
            coverVideoRef.current?.play()
          }, 100)
        }, 300)
      } else if (!e.target.classList.contains('project-image-animated-video')) {
        coverVideoRef.current?.classList.remove('pop-up-video')

        setTimeout(() => {
          coverVideoRef.current?.classList.remove('pop-up-video-z-up')
          coverVideoRef.current?.pause()
          setShowCover(true)
        }, 300)
      }
    }
  }

  useEffect(() => {
    if (animatedImageSource && thumbnailHovered?.type === 'video')
      document.addEventListener('click', handleClick)

    return () => {
      if (animatedImageSource && thumbnailHovered?.type === 'video')
        document.removeEventListener('click', handleClick)
    }
  })

  // when showCover, request full screen for the video
  // useEffect(() => {
  //   if (animatedImageSource && thumbnailHovered?.type === 'video') {
  //     if (showCover && coverVideoRef.current) {
  //       console.log('k')
  //       coverVideoRef.current?.requestFullscreen()
  //     } else {
  //       // document.exitFullscreen()
  //     }
  //   }
  // }, [showCover, animatedImageSource, thumbnailHovered?.type, coverVideoRef])

  return (
    <li
      ref={listItemEl}
      className={`list-item project-item${show ? '' : ' list-item-hidden'}${
        animatedImageSource && thumbnailHovered.type === 'gif'
          ? ' project-item-has-animated-gif'
          : ''
      }${
        animatedImageSource && thumbnailHovered.type === 'video'
          ? ' project-item-has-animated-video'
          : ''
      }`}
      style={{
        zIndex: order,
      }}
    >
      <div className="list-image-wrapper">
        {showCover && (
          <>
            <img
              ref={coverRef}
              src={imageSource}
              alt={name}
              className="list-image project-image"
            />
            {animatedImageSource && thumbnailHovered.type === 'video' && (
              <span className="click-watch-tag">click to watch trailer</span>
            )}
          </>
        )}

        {animatedImageSource && thumbnailHovered.type === 'gif' && (
          <img
            src={animatedImageSource}
            alt={name}
            className="list-image project-image-animated-gif"
          />
        )}

        {animatedImageSource && thumbnailHovered.type === 'video' && (
          <video
            ref={coverVideoRef}
            controls
            alt={name}
            className="list-image project-image-animated-video"
            style={{
              display: showCover ? 'none' : 'unset',
            }}
          >
            <source src={animatedImageSource} type="video/mp4" />
          </video>
        )}
      </div>

      {/* -------------------------------------------------------------------------- */}

      <div
        className={`list-content project-content${
          show ? '' : ' list-content-hidden'
        }`}
      >
        <div className="project-info">
          <p className="project-year">
            <span>{parseProjectYear(year, fullConferenceName)}</span>
            {year.includes('NEW_') && newProject}
          </p>
          {parseProjectAward(award) && (
            <p className="project-award">{parseProjectAward(award)}</p>
          )}
        </div>

        <div className="list-content-center">
          <p className="list-item-title">
            <span className="item-name">{name}</span>{' '}
            <span className="item-title">{title}</span>
          </p>
          {authorList}
        </div>

        <Highlights target={target} highlights={highlights} />

        <ListLinks
          name={name}
          links={[
            {
              name: 'doi',
              value: doi,
            },
            {
              name: 'paper',
              value: parseProjectPaper(paper),
            },
            {
              name: 'video',
              value: video,
            },
            {
              name: 'preview',
              value: preview,
            },
            {
              name: 'website',
              value: parseProjectWebsite(website),
            },
            {
              name: 'bibtex',
              value: bibTex,
            },
            ...(extra ?? []),
          ]}
          comingSoon={true}
        />
      </div>
    </li>
  )
}

const Person = memo(
  ({
    image,
    name,
    title,
    research,
    status,
    email,
    website,
    twitter,
    time,
    now,
    info,
    bio,
    bioInline,
    showBioInline,
  }) => {
    let imageSource
    if (image.length) {
      imageSource = require(`../assets/people/${image}`)
    }

    const isAlumni = status === 'alumni'
    const isImportantAlumni =
      isAlumni && ['Postdoc', 'PhD Student'].includes(title)

    return (
      <AnimatePresence initial={false}>
        <motion.li
          layout
          initial={{ opacity: 0 }}
          animate={{
            opacity: 1,
          }}
          exit={{ opacity: 0 }}
          key={name}
          className="list-item person-item"
        >
          {!isAlumni && imageSource && (
            <img
              src={imageSource}
              alt={name}
              className={`list-image people-image people-image-${status}`}
            />
          )}
          {!isAlumni && !imageSource && (
            <div
              className={`list-image people-image-placeholder people-image-${status}`}
            ></div>
          )}

          <div
            className={`list-content people-content${
              isAlumni ? ' people-content-alumni' : ''
            }`}
          >
            {!isAlumni && (
              <div className="people-info">
                <p className="people-title">{title}</p>
              </div>
            )}

            <div className="list-content-center">
              <p className="list-item-title person-item-title">
                <span className={isAlumni ? '' : 'item-name'}>
                  {isAlumni && info ? (
                    <a
                      href={website}
                      target="_blank"
                      rel="noopenner noreferrer"
                    >
                      {name}
                    </a>
                  ) : (
                    name
                  )}
                </span>
                {/* time */}
                {isImportantAlumni ? (
                  <span className="item-title">
                    {getAlumniTime(time, isImportantAlumni)}
                  </span>
                ) : (
                  ''
                )}
              </p>

              {research && (!isAlumni || info) && !showBioInline && (
                <p className="people-research">
                  {isAlumni && info
                    ? `Now - ${now}`
                    : isAlumni
                      ? null
                      : research}
                  {/* {isAlumni ? null : research} */}
                </p>
              )}

              {showBioInline && (!isAlumni || info) && (
                <div className="people-bio">
                  {bioInline.map((bioP, ind) => {
                    return <p key={`bio-${ind}`}>{bioP}</p>
                  })}
                </div>
              )}
            </div>

            {!isAlumni && (
              <ListLinks
                name={name}
                links={[
                  {
                    name: 'bio',
                    value: showBioInline ? undefined : bio,
                  },
                  {
                    name: 'email',
                    value: email,
                  },
                  {
                    name: 'website',
                    value: website,
                  },
                  {
                    name: 'twitter',
                    value: twitter,
                  },
                ]}
              />
            )}
          </div>
        </motion.li>
      </AnimatePresence>
    )
  }
)
