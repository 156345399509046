import React, { Component, createRef } from 'react'

import { copyToClipboard } from './utils'

import '../css/ListLink.scss'

export class ListLinks extends Component {
  render() {
    const { name, links, comingSoon } = this.props

    const listLinks = links.map(link => {
      if (!link.value || !link.value.length) return null
      return <ListLink key={`${name}_${link.name}`} name={name} link={link} />
    })

    for (let link of listLinks) {
      if (link !== null) return <ul className="list-item-links">{listLinks}</ul>
    }

    if (!comingSoon) return null

    return (
      <ul className="list-item-links">
        <li>
          <span>coming soon</span>
          {/* <span></span> */}
        </li>
      </ul>
    )
  }
}

export class ListLink extends Component {
  constructor(props) {
    super(props)

    this.emailMessage = createRef()
  }

  render() {
    const { link } = this.props

    let ele
    if (link.name === 'email' || link.name === 'bibtex') {
      ele = (
        <p
          onClick={() => {
            copyToClipboard(link.value, this.emailMessage.current)
          }}
        >
          <span ref={this.emailMessage} className="copy-message">
            click to copy
          </span>
          {link.name}
        </p>
      )
    } else {
      ele = (
        // <a href={link.value} target="_blank" rel="noopener noreferrer">
        <a href={link.value}>{link.name}</a>
      )
    }

    return <li>{ele}</li>
  }
}
