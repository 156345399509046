import React, { Component } from 'react'
// import { TwitterTweetEmbed } from 'react-twitter-embed'
import Header from '../components/Header'
import { PeopleList, ProjectList } from '../components/List'
import Footer from '../components/Footer'
import '../css/Education.scss'
export default class Education extends Component {
  componentDidMount() {
    document.title = 'AI in K-12 Education | Creativity Lab'
  }

  render() {
    return (
      <>
        <Header highlighted="research" />
        <div className="list-container list-container-projects list-container-ai">
          <div className="ai-header">
            <h2 className="ai-h2">
              AI in K-12
              <br />
              Education
            </h2>
            <div className="collection-intro">
              <p>
                AI is drastically reshaping every aspect of our society. With
                its growing impact, it is crucial to incorporate AI education
                into the K-12 curriculum to ensure that future generations are
                prepared to embrace the opportunities and challenges this
                evolving technology presents. Unfortunately, teaching AI
                presents its own set of challenges, primarily due to its
                inherent complexity, like other domains that grapple with
                similar obstacles, such as computer science and math. The
                intricate and abstract concepts of AI can make it less engaging
                for students to develop a learning interest. We must find
                innovative approaches to make AI education more accessible,
                interactive, and enjoyable, ensuring that students remain
                motivated and inspired throughout their AI learning journey.
              </p>
              <p>
                We leverage interactive graphic content of various forms, such
                as comics, diagrams, and visualizations, as the main teaching
                and learning materials in both informal and formal settings for
                K-12 AI education. We will develop, deploy, and evaluate an
                AI-assisted interactive and immersive platform to enable both
                K-12 educators and students to create expressive and engaging
                graphical educational content to facilitate the teaching and
                learning of AI.
              </p>
              <p>
                The list of our prior work below demonstrates that we have the
                necessary design and research expertise and engineering skills
                to design, implement, and evaluate the envisioned system.
              </p>
            </div>
          </div>

          <h2
            className="list-h2"
            style={{
              marginTop: '2rem',
            }}
          >
            Team
          </h2>
          <PeopleList
            status="current"
            byPeople={true}
            peopleList={['Haijun Xia', 'Sangho Suh']}
            showBioInline={true}
          />

          <h2
            className="list-h2"
            style={{
              marginTop: '2rem',
            }}
          >
            Relevant Works
          </h2>

          <img
            className="education-project-showcase-img"
            src={require('../assets/others/education-project-showcase.png')}
            alt="Project Showcase"
          ></img>

          <ProjectList
            target={'education'}
            filters={[]}
            negativeFilters={[]}
            byPapers={true}
            papers={[
              'codetoon',
              'comics',
              'comics-cs1',
              'codingstrip',
              'privacytoon',
              'datatoon',
              'mathmap',
              'metaphorian',
              'sensecape',
              'graphologue',
              'collaboration-in-programming',
            ]}
            extraLists={['sangho']}
            fullConferenceName={true}
          />
        </div>
        <Footer />
      </>
    )
  }
}
