import React from 'react'

import '../css/Highlights.scss'

export const Highlights = ({ target, highlights }) => {
  if (!highlights || !highlights.show.includes(target)) return null

  const highlightComponents = []

  Object.keys(highlights.items).forEach((highlightType, itemIndex) => {
    const highlightInfo = highlights.items[highlightType]

    switch (highlightType) {
      case 'video':
        highlightComponents.push(
          <div key={`${itemIndex}-${highlightType}`} className="highlight-item">
            <video
              controls
              // controlsList="nodownload"
              alt={highlightInfo.title}
              className="highlight-video"
            >
              <source
                src={require(`../assets/videos/${highlightInfo.source}`)}
                type="video/mp4"
              />
            </video>
            <span>{highlightInfo.title}</span>
          </div>
        )
        break

      case 'youtube':
        highlightComponents.push(
          <div key={`${itemIndex}-${highlightType}`} className="highlight-item">
            <iframe
              // width="100%"
              // height="320"
              src={highlightInfo.source + '?showinfo=0&rel=0'}
              title="YouTube video player"
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
              allowFullScreen
              // className="ai-video-iframe"
            ></iframe>
            <span>{highlightInfo.title}</span>
          </div>
        )
        break

      default:
        break
    }
  })

  return <div className="project-highlights">{highlightComponents}</div>
}
