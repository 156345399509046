import React, { Component } from 'react'
// import { TwitterTweetEmbed } from 'react-twitter-embed'

import Header from '../components/Header'
import { ProjectList } from '../components/List'
import Footer from '../components/Footer'
import { HeadHelmet } from '../components/Helmet'

import Graphologue from '../assets/thumbnails/graphologue.jpg'

// import '../css/AI.scss'

export default class AI extends Component {
  render() {
    return (
      <>
        <HeadHelmet
          title={'AI | Creativity Lab'}
          description={
            'Creativity Lab at UC San Diego are exploring ways to leverage interactive graphical representations to address limitations in human-AI interaction.'
          }
          url={'ai'}
          image={Graphologue}
        />
        <Header highlighted="research" />
        <div className="list-container list-container-projects list-container-ai">
          <div className="ai-header">
            <h2 className="ai-h2">
              Human-AI
              <br />
              Interaction
            </h2>
            <div className="collection-intro">
              <p>
                The popularity of LLMs has surged thanks to their ability to
                instantly produce high-quality textual responses. However,
                text-based conversational interfaces can present challenges when
                dealing with complex information tasks. Text, as a sequence of
                symbols, struggles to effectively convey complex relationships
                among concepts. Moreover, the linear structure of conversations
                can hinder the flexible exploration and organization of desired
                information. We are exploring ways to leverage interactive
                graphical representations to address these limitations.
              </p>
              <p>
                We introduce <b>Graphologue</b> and <b>Sensecape</b>. At a
                micro-level, Graphologue transforms GPT-4-generated text into
                interactive node-link diagrams in real-time, facilitating rapid
                comprehension and exploration of information. At a macro-level,
                Sensecape enables users to spatially organize information
                obtained from GPT-4, offering a flexible way to organize and
                make sense of large amounts of information.
              </p>
              <p>
                These research projects are grounded in our previous work aimed
                at enabling intuitive and flexible human-AI collaboration. See
                more below!
              </p>
            </div>
          </div>

          {/* <div className="ai-videos">
            <div className="ai-video">
              <h2 className="list-h2">Graphologue</h2>
              <span className="credits">
                Peiling Jiang, Jude Rayan, Steven Dow, Haijun Xia
              </span>

              <iframe
                width="100%"
                height="320"
                src="https://www.youtube.com/embed/92QREkwrwRQ"
                title="YouTube video player"
                // frameBorder="0"
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                allowFullScreen
                className="ai-video-iframe"
              ></iframe>

              <TwitterTweetEmbed tweetId={'1646917869115166720'} />
            </div>

            <div className="ai-video">
              <h2 className="list-h2">Sensecape</h2>
              <span className="credits">
                Sangho Suh, Bryan Min, Srishti Palani, Haijun Xia
              </span>

              <iframe
                width="100%"
                height="320"
                src="https://www.youtube.com/embed/MIfhunAwZew"
                title="YouTube video player"
                // frameBorder="0"
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                allowFullScreen
                className="ai-video-iframe"
              ></iframe>

              <TwitterTweetEmbed tweetId={'1646919380704559104'} />
            </div>
          </div> */}

          <h2
            className="list-h2"
            style={{
              marginTop: '2rem',
            }}
          >
            Latest Works
          </h2>

          <ProjectList
            target={'ai'}
            filters={['ai-now']}
            negativeFilters={[]}
          />

          <h2
            className="list-h2"
            style={{
              marginTop: '0rem',
            }}
          >
            Previous Works
          </h2>

          <ProjectList
            target={'ai'}
            filters={['ai']}
            negativeFilters={['ai-now']}
          />
        </div>

        <Footer />
      </>
    )
  }
}
