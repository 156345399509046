import React from 'react'

export const PageNotFound = () => {
  return (
    <>
      <h1>
        <b>😵 Sorry, we cannot find the page that you are looking for.</b>{' '}
        Please go to <a href="/">🔬 research</a> page to see our works,{' '}
        <a href="/people">👨‍🔬👩‍🔬 team</a> page to see the amazing people in lab,
        or <a href="/contact">👋 contact</a> page to send us a message.
      </h1>
    </>
  )
}
