import React, { Component } from 'react'
// import { TwitterTweetEmbed } from 'react-twitter-embed'
import Header from '../components/Header'
import { PeopleList, ProjectList } from '../components/List'
import Footer from '../components/Footer'
import '../css/Education.scss'
export default class Education extends Component {
  componentDidMount() {
    document.title =
      'Rethinking Data Analysis and Communication with Human-Centered AI | Creativity Lab'
  }

  render() {
    return (
      <>
        <Header highlighted="research" />
        <div className="list-container list-container-projects list-container-ai">
          <div className="ai-header ai-header-column">
            <h2 className="ai-h2 ai-h2-smaller">
              Rethinking Data Analysis and Communication with Human-Centered AI
            </h2>
            <div className="collection-intro">
              <p>
                Workflows involving managing complex information, iterative
                ideation and analysis, and multi-user collaboration are
                increasingly challenging in today’s rapidly evolving hybrid work
                environments, requiring users to juggle multiple applications,
                devices, meetings, and work locations. Powerful AI models,
                however, present opportunities to remove the drudgery from
                traditional workflows, enable dynamic context-sensitive shared
                information spaces, and foster fluid human-human and human-AI
                workflows.
              </p>
              <p>
                We ground our exploration of AI for the future of work within
                data analysis and communication because they are integral to our
                data-centric world and have woven themselves into the fabric of
                science, education, engineering, marketing, policy-making, and
                beyond. We envision designing human-AI collaboration to address
                critical bottlenecks in complex workflows by leveraging LLMs to
                enhance data gathering, wrangling, analysis, visualization,
                communication, and iteration. Please refer to our proposal for
                details.
              </p>
              <p>
                We are a team with significant expertise in Human-Centered AI
                and data analysis and visualization. Please see our bios and
                prior works below.
              </p>
            </div>
          </div>

          <h2
            className="list-h2"
            style={{
              marginTop: '2rem',
            }}
          >
            Team
          </h2>
          <PeopleList
            status="current"
            byPeople={true}
            peopleList={['Haijun Xia', 'Jim Hollan']}
            showBioInline={true}
          />

          <h2
            className="list-h2"
            style={{
              marginTop: '2rem',
            }}
          >
            Relevant Works
          </h2>

          {/* <img
            className="education-project-showcase-img"
            src={require('../assets/others/education-project-showcase.png')}
            alt="Project Showcase"
          ></img> */}

          <ProjectList
            target={'education'}
            filters={[]}
            negativeFilters={[]}
            byPapers={true}
            papers={[
              'dataparticles',
              'crossdata',
              'graphologue',
              'exp_comp_notebooks',
              'logit',
              'crosscode',
              'dataink',
            ]}
            extraLists={['hollan']}
            fullConferenceName={true}
          />
        </div>
        <Footer />
      </>
    )
  }
}
