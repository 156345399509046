import Publications from '../assets/publications.json'
import People from '../assets/people.json'

export const parseProjectYear = (year, fullConferenceName = false) => {
  year = year.replace('NEW_', '')

  if (fullConferenceName) {
    const conferences = Publications.conferences

    for (const conferenceAbbr in conferences) {
      if (year.includes(conferenceAbbr)) {
        return year.replace(conferenceAbbr, conferences[conferenceAbbr])
      }
    }
  }

  return year
}

export const parseProjectAward = award => {
  if (!award.length) return false
  if (award === 'HM') return '🏅 Honorable Mention'
  else if (award === 'BEST') return '🏆 Best Paper'
}

export const parseProjectPaper = paper => {
  if (!paper.length) return ''
  if (paper.includes('http') && paper.includes('//')) {
    // external link
    return paper
  } else {
    // internal link
    return `./papers/${paper}`
  }
}

export const parseProjectWebsite = website => {
  if (!website.length) return ''
  if (website.includes('http') && website.includes('//')) {
    // external link
    return website
  } else {
    // internal link
    // return `./projects/${website}`
    return `./${website}`
  }
}

export const sortPeopleList = (people, ordering, status) => {
  if (status === 'current') {
    const groupedPeople = []
    for (let type of ordering) {
      const filteredPeople = people.filter(person => person.category === type)
      groupedPeople.push(
        ...filteredPeople.sort((a, b) =>
          getLastName(a.name).localeCompare(getLastName(b.name))
        )
      )
    }
    return groupedPeople
  } else {
    return people
      .sort((a, b) => getLastName(a.name).localeCompare(getLastName(b.name)))
      .sort((a, b) => b.time[1] - a.time[1])
  }
}

export const sortPeopleListsByYear = (people, ordering, status) => {
  // return an object, key is the years, value is the sorted people list
  const peopleByYear = {}
  for (let person of people) {
    const year = person.time[1]
    if (peopleByYear[year]) peopleByYear[year].push(person)
    else peopleByYear[year] = [person]
  }

  for (let year in peopleByYear) {
    peopleByYear[year] = sortPeopleList(peopleByYear[year], ordering, status)
  }

  return peopleByYear
}

const getLastName = name => {
  const nameList = name.split(' ')
  return nameList[nameList.length - 1]
}

// https://codepen.io/eclarrrk/pen/ZZywZv
export function copyToClipboard(text, message) {
  var dummy = document.createElement('input')
  document.body.appendChild(dummy)
  dummy.setAttribute('value', text)
  dummy.select()
  document.execCommand('copy')
  document.body.removeChild(dummy)

  message.innerHTML = 'copied to clipboard'
  setTimeout(() => {
    message.innerHTML = 'click to copy'
  }, 1000)
}

/* -------------------------------------------------------------------------- */

// project and people

export const findProject = shortcut => {
  const project = Publications.papers.find(
    project => project.shortcut === shortcut
  )

  return project
}

export const findPersonBio = bioShortcut => {
  const person = People.directory.find(person => person.bio === bioShortcut)

  return person
}

export const projectHasCategory = (projectCategories, categories) => {
  for (let category of categories) {
    if (projectCategories.includes(category)) return true
  }
  return false
}

export const getAlumniTime = (time, important) => {
  if (!important || time[0] === time[1]) return ` ${time[1]}`
  else return ` ${time[0]} - ${time[1]}`
}
