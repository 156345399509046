import React, { Component, Suspense } from 'react'

import Header from '../components/Header'
import CollectionIntro from '../components/CollectionIntro'
import { ProjectList } from '../components/List'

const collectionConfigurations = {
  l: {
    title: 'Language-Oriented Interaction',
    introContent: (
      <p>Here are the works using Language-Oriented Interaction.</p>
    ),
  },
}

export default class Collection extends Component {
  render() {
    const { identifier } = this.props
    const collectionConfiguration = collectionConfigurations[identifier]

    return (
      <>
        <Header highlighted="research" />
        <div className="list-container list-container-projects">
          <Suspense>
            <h2
              // ref={publicationRef}
              className="list-h2"
              // onClick={clearFilter}
              style={{
                cursor: 'pointer',
              }}
            >
              {collectionConfiguration.title}
            </h2>
            <CollectionIntro
              introContent={collectionConfiguration.introContent}
            />
            <ProjectList filters={[identifier]} negativeFilters={[]} />
          </Suspense>
        </div>
      </>
    )
  }
}
