import React, { Component } from 'react'
import Footer from '../components/Footer'
import Header from '../components/Header'

import '../css/Join.scss'
import '../css/pages.scss'

import DIB from '../assets/others/dib.jpg'
import ReactMarkdown from 'react-markdown'

export default class Join extends Component {
  constructor(props) {
    super(props)

    this.state = {
      text: '',
    }
  }

  componentDidMount() {
    fetch(require('../assets/markdownPages/join.md'))
      .then(response => {
        return response.text()
      })
      .then(text => {
        this.setState({
          text: text,
        })
      })

    // document.title = 'Join Us | Creativity Lab'
  }

  render() {
    return (
      <>
        <Header highlighted="join" />
        <div className="page-view">
          <img
            src={DIB}
            alt="Design and Innovation Building"
            className="dib-img"
          />
          <ReactMarkdown>{this.state.text}</ReactMarkdown>
        </div>
        <Footer />
      </>
    )
  }
}
