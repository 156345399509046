import React from 'react'
import ReactDOM from 'react-dom/client'
import { BrowserRouter, Routes, Route } from 'react-router-dom'

import './css/index.scss'

import App from './App'
import Team from './pages/Team'
import Contact from './pages/Contact'
import ScrollToTop from './components/ScrollToTop'
import Join from './pages/Join'
import Project from './pages/Project'
import Collection from './pages/Collection'
import AI from './pages/AI'
import Education from './pages/Education'
import HumanCenteredAI from './pages/HumanCenteredAI'
import { RedirectHub } from './pages/RedirectHub'

const rootElement = document.getElementById('root')
const root = ReactDOM.createRoot(rootElement)
const dom = (
  <React.StrictMode>
    <BrowserRouter>
      <ScrollToTop />
      <Routes>
        <Route exact path="/" element={<App />} />
        <Route exact path="/team" element={<Team />} />
        <Route exact path="/contact" element={<Contact />} />
        <Route exact path="/join" element={<Join />} />
        {/* -------------------------------------------------------------------------- */}
        <Route exact path="/ai" element={<AI />} />
        <Route exact path="/education" element={<Education />} />
        <Route exact path="/HumanCenteredAI" element={<HumanCenteredAI />} />
        <Route exact path="/language" element={<Collection identifier="l" />} />
        {/* -------------------------------------------------------------------------- */}
        <Route path="/*" element={<Project />} />
        {/* <Route path="/projects/*" element={<Projects />} /> */}

        {/* Hard auto redirects */}
        {[
          {
            path: '/colorfield',
            link: 'https://rzgpfk.csb.app/',
          },
        ].map(({ path, link }) => {
          return (
            <Route
              exact
              key={path}
              path={path}
              element={<RedirectHub link={link} />}
            />
          )
        })}
      </Routes>
    </BrowserRouter>
  </React.StrictMode>
)

root.render(dom)
