import React, { Component } from 'react'

// import '../css/Intro.scss'

export default class CollectionIntro extends Component {
  render() {
    const { introContent } = this.props
    return <div className="collection-intro">{introContent}</div>
  }
}
