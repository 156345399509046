import React, { Component } from 'react'
// import DesignLab from '../assets/others/design-lab.svg'

import '../css/Footer.scss'

export default class Footer extends Component {
  render() {
    return (
      <footer>
        <div className="logos">
          <p className="lab-title">Creativity Lab</p>
          {/* <div className="separator"></div>
          <img
            src={DesignLab}
            alt="UCSD The Design Lab"
            className="design-lab"
          /> */}
        </div>
        <span className="rights">
          Copyright © 2024 The Creativity Lab
          <span className="large-screen-dot">.</span>
          <br className="small-screen-break" /> All rights reserved
        </span>
      </footer>
    )
  }
}
