import React, { Component, Suspense } from 'react'
import Footer from '../components/Footer'
import Header from '../components/Header'
import { PeopleAlumniList, PeopleList } from '../components/List'
import { HeadHelmet } from '../components/Helmet'

import Lab from '../assets/others/lab.png'

export default class Team extends Component {
  render() {
    return (
      <>
        <HeadHelmet
          title={'Team | Creativity Lab'}
          description={'Creativity Lab at UC San Diego'}
          url={'team'}
          image={Lab}
        />
        <Header highlighted="team" />
        <div className="list-container list-container-people">
          {/* <Intro
            introContent={
              <p>
                Our mission is to advance and support creativity and
                productivity by innovating on the fundamentals of Human-computer
                interaction, including understanding human cognition, exploring
                novel input modalities, and designing alternative user interface
                paradigms, for design activities, computer-supported cooperative
                work, virtual / augmented / mixed reality, and information
                visualization, and many other domains.
              </p>
            }
          /> */}
          <Suspense>
            <h2 className="list-h2">Members</h2>
            <PeopleList status="current" />
            <h2 className="list-h2">Alumni</h2>
            <h3 className="list-h3">Postdocs</h3>
            <PeopleList status="alumni" categories={['Postdoc']} info={true} />
            {/* <h3>PhD Students</h3> */}
            <h3 className="list-h3">Visitors, Interns, and Rotations</h3>
            <PeopleAlumniList
              status="alumni"
              categories={[
                'Visiting Scholar',
                'Visiting Postdoc',
                'Rotation PhD Student',
                'Visiting Student',
                'Research Intern',
                'Intern',
                'Master Student',
                'Undergraduate Student',
                'High School Student',
              ]}
              info={false}
            />
          </Suspense>
        </div>

        <Footer />
      </>
    )
  }
}
